import React from 'react';
import dayjs from 'dayjs';
import { useMemoizedFn } from 'ahooks';
import classNames from 'classnames';
import { logCount } from '@/utils/logger/arms-config';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import i18n from '@/locales/init';
import { IWritingTask1Evaluation } from '@/api/history';
import { saveEssayRecordData } from '@/api/writing/essay';
import { usePDFStateCheck } from '@/modules/Writing/hooks/usePDFStateCheck';
import { StatusLabel } from '../../components/StatusLabel';
import lessModule from './item.module.less';

const getStatus = (state: number) => {
  if (state === 1) {
    return i18n.t('common.dauyan_in_progress');
  }
  if (state === 2) {
    return i18n.t('common.dauyan_grading_in_progress');
  }
  return i18n.t('common.dauyan_some_complete');
};

export interface IWritingTask1EvaluationItemProps {
  item: IWritingTask1Evaluation;
  onHomePage?: boolean;
}

export const WritingTask1EvaluationItem = React.memo<IWritingTask1EvaluationItemProps>(({
  item, onHomePage
}) => {
  const handleClick = useMemoizedFn(() => {
    logCount('小作文批改历史记录进入详情');
    window.location.href = `${window.location.origin}/#/writing-feedback-task-1/${item.paper_id}/${item.id}?from=list`;
  });

  const handleRedo = async () => {
    try {
      const res = await saveEssayRecordData({
        id: 0,
        paper_id: item.paper_id
      });
      if (res.data.id) {
        const res1 = await saveEssayRecordData({
          paper_id: item.paper_id,
          id: res.data.id,
          content: item.content,
        });
        if (res1.data.id) {
          window.location.href = `${window.location.origin}/#/writing-task-1/${item.paper_id}/${res.data.id}`;
        }
      }
    } catch (err) {
      console.log('Error saving;');
    }

  };

  const { exporting, exportPDFFile } = usePDFStateCheck(item.id, 2);
  const handleDownloadPdf = useMemoizedFn(() => {
    exportPDFFile();
  });

  return <div className={classNames(lessModule.itemWrapper, onHomePage && lessModule.onHomePage)} onClick={handleClick}>
    <div className={lessModule.title}>{item.paper_title}</div>
    <div className={lessModule.content}>{item.paper_content}</div>
    <div className={lessModule.status}>
      <StatusLabel text={getStatus(item.status)} complete={item.status === 3}></StatusLabel>
    </div>
    {
      onHomePage ? <>
        <div className={lessModule.score}>{item.status === 3 ? item.score : ''}</div>
        <div className={lessModule.action}>
          {
            item.status === 3 && <div className={lessModule.redo}>
              <SimpleButton data-track-key={'history-writing-evaluate1-restart'} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleRedo();
              }}>{i18n.t('common.dauyan_recorrect_task1')}</SimpleButton>
            </div>
          }
          {
            item.status === 3 && <div className={lessModule.redo}>
              <SimpleButton data-track-key={'history-writing-evaluate1-download'} loading={exporting} onClick={(e)=>{
                e.stopPropagation();
                e.preventDefault();
                handleDownloadPdf();

              }}>{i18n.t('common.dauyan_download')}</SimpleButton>
            </div>
          }
        </div>
      </> :
        <>
          <div className={lessModule.time}>{dayjs(item.update_time * 1000).format('YYYY-MM-DD HH:mm')}</div>
          <div className={lessModule.score}>{item.status === 3 ? item.score : ''}</div>
          <div className={lessModule.action}>
            {
              item.status === 3 && <div className={lessModule.redo}>
                <SimpleButton data-track-key={'history-writing-evaluate1-restart'} onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleRedo();
                }}>{i18n.t('common.dauyan_recorrect_task1')}</SimpleButton>
              </div>
            }
            {
              item.status === 3 && <div className={lessModule.redo}>
                <SimpleButton data-track-key={'history-writing-evaluate1-download'} loading={exporting} onClick={(e)=>{
                  e.stopPropagation();
                  e.preventDefault();
                  handleDownloadPdf();

                }}>{i18n.t('common.dauyan_download')}</SimpleButton>
              </div>
            }
          </div>
        </>
    }
  </div>;
});
